@font-face {
  font-family: "Pilat Wide";
  src: local("Pilat Wide Demi"), url("./fonts/Pilat Wide/Pilat Wide Demi.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Pilat Wide";
  src: local("Pilat Wide Regular"), url("./fonts/Pilat Wide/Pilat Wide Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Remake";
  src: local("Remake Book"), url("./fonts/Remake/Remake-Book_V01.woff") format("woff");
  font-weight: 350;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: "Pilat Wide", "Remake" "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
  width: 100%;
}

main {
  height: 100vh;
}

/* code {
  font-family: "Pilat Wide", "Remake Book" "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
} */

h1 {
  font-family: 'Remake';
  font-size: 3.74rem;
  letter-spacing: 6px;
  margin-bottom: 0px;
}

h2 {
  font-family: 'Remake';
  font-size: 1.5rem;
}

h3 {
  font-family: 'Pilat Wide';
  font-size: 1.5rem;
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 12px;
}

h4 {
  font-family: 'Remake';
  font-size: 1.5rem;
  margin: 12px 0px;
}

h5 {
  font-family: 'Remake';
  margin-top: 6px;
  margin-bottom: 6px;
}

a {
  margin-top: 8px;
  margin-bottom: 8px;
}

button.connect-btn {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}

.crossmint-btn {
  padding: 32.2609px 0px !important;
  position: relative !important;
  width: 255px !important;
  height: 60px !important;
  border-radius: 76px !important;
  background: radial-gradient(44.96% 391.37% at 49.64% 50%, #E21BDA 2.67%, #29A9B1 100%) !important;
  box-shadow: 0px 0px 29.9565px rgba(255, 255, 255, 0.5), inset 0px 6.91304px 25.3478px #4B7EFF, inset 0px -6.91304px 9.21739px #FF86EC !important;
  font-size: 15px !important;
  font-family: 'Remake' !important;
  font-weight: 400 !important;
}

.crossmint-btn:hover {
  opacity: 1 !important;
}

.wallet-adapter-button:not([disabled]):hover {
  background: radial-gradient(44.96% 391.37% at 49.64% 50%, #B5B5B5 2.67%, #5E22FF 100%) !important;
  box-shadow: 0px 0px 29.9565px rgba(94, 34, 255, 0.5), inset 0px 6.91304px 25.3478px #5E22FF, inset 0px -6.91304px 9.21739px #C1AAFF !important;
}